import React, { useEffect, useState } from "react"
import WorkflowCard from "../../components/dialog/workflow-card";
import WorkflowConfig from "../../../workflow-config";
import api from "../../services/api";
import { LinearProgress } from "@material-ui/core";
import Theme from "../../components/layout/theme";
import { ifNotHaveAccessRedirect } from "../../services/auth";

export default function WorkflowCardPage() {
  const isSSR = typeof window === "undefined";

  const urlParams = new URLSearchParams(!isSSR ? window.location.search : undefined);
  const preFlightWorkflow = WorkflowConfig.workflows[urlParams.get('workflow') || WorkflowConfig.defaultWorkflow];
  const [currentWorkflow] = useState(preFlightWorkflow);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      if (ifNotHaveAccessRedirect('process.view')) return <></>;

      handleLoadData();
    })();
  }, []);

  const handleLoadData = async() => {
    setLoading(true);
    const response = await api.get(`/workflow/${currentWorkflow.id}/${urlParams.get('id')}`);
    setData(response.data);
    setLoading(false);
  };

  const handleAfterTransition = async () => {
    handleLoadData();
  };

  return (
    <Theme>
      {loading ? <LinearProgress /> : (
        <WorkflowCard
          card={data}
          preLoadedData={data}
          open={true}
          currentWorkflow={currentWorkflow}
          onAfterTransition={handleAfterTransition}
        />
      )}
    </Theme>
  );
}